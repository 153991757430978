import React from 'react';
import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";
import BackArrow from "../images/backArrow.svg";
import {Link} from "gatsby";
import Decoration from "../images/decoration.svg";
import Seo from "./seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1110px;
  align-self: center;
  margin-bottom: 52px;
  position: relative;
  height: 100%;
  @media screen and (max-width: 1100px) {
    width: 1000px;
    margin-bottom: 42px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
    margin-bottom: 32px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
    margin-bottom: 22px;
  }
`;

const OwnLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 52px;
  width: fit-content;
  @media screen and (max-width: 1100px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }
  @media screen and (max-width: 960px) {
    font-size: 12px;
  }
  @media screen and (max-width: 800px) {
    font-size: 10px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 22px;
  color: #103072;
  margin-bottom: 50px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 90px;
`;

const RowContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  justify-content: space-between;
  padding-right: 60px;
  padding-left: 19px;
  box-sizing: border-box;
`;

const BackForLogo = styled.div`
  width: 255px;
  height: 209px;
  background: #ECF2FF;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  background: #DFE8FF;
  border-radius: 6px;
  justify-content: space-between;
  padding: 12px 66px 12px 19px;
  width: 825px;
  box-sizing: border-box;
  margin-bottom: -24px;
  @media screen and (max-width: 1100px) {
    width: 725px;
  }
  @media screen and (max-width: 960px) {
    width: 580px;
  }
  @media screen and (max-width: 800px) {
    width: 425px;
  }
`;

const NameOfWork = styled(Text)`
  max-width: 616px;
  line-height: 140%;
  @media screen and (max-width: 1100px) {
    max-width: 516px;
    line-height: 140%;
  }
  @media screen and (max-width: 960px) {
    max-width: 416px;
    line-height: 140%;
  }
  @media screen and (max-width: 800px) {
    max-width: 280px;
    line-height: 140%;
  }
`;

const SingleProject = ({pageContext}) => {
    console.log("Page2", pageContext)
    const ReturnImages = () => {
        return (
            <>
                <Image src={Decoration} alt="decor"/>
            </>
        )
    }

    const Image = styled.img`
      position: absolute;
      left: -460px;
      top: 130px;
      z-index: -1;
    `;

    return (
        <MainContainer>
            <Seo title={pageContext.currentValue.node.seo_title}
                 description={pageContext.currentValue.node.seo_description}
                 keywords={pageContext.currentValue.node.seo_keywords.split(',')}
            />
            <div style={{alignSelf: 'center', display: "flex", flexDirection: "column"}}>
                <Header/>
                <Container>
                    <ReturnImages/>
                    <OwnLink to="/projects">
                        <img style={{marginRight: "16px"}} src={BackArrow} alt="backArrow"/>
                        <Text style={{color: "#595959"}}>
                            Назад к проектам
                        </Text>
                    </OwnLink>
                    <Title>
                        {pageContext.currentValue.node.title}
                    </Title>
                    {pageContext.currentValue.node.partner.map((item, index) => (
                        <RowContainer>
                            <div style={{textAlign: "center", marginRight: "30px"}}>
                                <BackForLogo>
                                    <img src={item.logo.localFile.url}
                                         alt="gazprooooooom"/>
                                </BackForLogo>
                                <Text>
                                    {item.partnerName}
                                </Text>
                            </div>
                            <div>
                                <Subtitle>
                                    <Text>
                                        Наименование работ
                                    </Text>
                                    <Text>
                                        Год
                                    </Text>
                                </Subtitle>
                                {item.work.map((item, index) => (
                                    <RowContainer2>
                                        <NameOfWork>
                                            {item.nameOfWork}
                                        </NameOfWork>
                                        <Text>
                                            {item.year}
                                        </Text>
                                    </RowContainer2>
                                ))}
                            </div>
                        </RowContainer>
                    ))}
                </Container>
            </div>
            <Footer/>
        </MainContainer>
    )
}

export default SingleProject;